<template lang="pug">
.dashboard-wrapper
  b-row
    b-col
      b-card(no-body, class="pt-2 px-2")
        filters(
          :customFiltersShow="true"
          :zoneFilterShow="zone_agency_filter"
          :agencyFilterShow="zone_agency_filter"
          :locationFilterShow="true",
          :dateRangeFilterShow="true",
          :locationDefaultValue="true",
          @changeFilter="updateFilter",
          @locations="updateLocations"
        )

  b-row(v-if="isLoading")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          :color="colors.primary"
        )

  div(v-else)
    b-row(v-if="!meetings_total")
      b-col
        b-card(class="text-center")
          h5(class="mb-0")
            | {{ $t('message.no_daily_dialogues_found') }}

    div(v-else)
      b-row
        b-col(sm="3")
          statistic-card-vertical-total(:statistic="meetings_total")
        b-col(sm="3")
          statistic-card-vertical-index(:statistic="happiness_index")
        b-col(sm="3")
          statistic-card-vertical-compliance(:statistic="compliance")
      
      b-row
        b-col(lg="6")
          bar-chart-location(
            v-if="locationDialogues.labels && locationDialogues.labels.length",
            :locations-data="locationDialogues"
          )
        b-col(lg="6")
          bar-chart-happiness(
            v-if="locationHappiness.labels && locationHappiness.labels.length",
            :happiness-data="locationHappiness"
          )
      b-row
        b-col(cols="12")
          heat-map-dialogues(
            v-if="dialoguesPerDay.length",
            :dialogues-per-day-data="dialoguesPerDay"
          )

      b-card
        feather-icon-button(v-if="commentsVisible", icon="ArrowLeftIcon", variant="info", size="21", class="mb-1" @clicked="handleComments(false)")
        b-table-simple(hover, small, caption-top, responsive)
          b-thead(v-if="!commentsVisible", head-variant="light")
            b-tr
              th {{ $t('happiness.date') }}
              th {{ $t('happiness.start_time') }}
              th {{ $t('happiness.end_time') }}
              th {{ $t('happiness.created_by') }}
              th {{ $t('happiness.location') }}
              th {{ $t('happiness.happy') }}
              th {{ $t('happiness.neutral') }}
              th {{ $t('happiness.sad') }}
              th {{ $t('happiness.mood') }}
              th {{ $t('label.load') }}
              th {{ $t('happiness.image') }}
              th {{ $t('happiness.action') }}
            b-tr(v-for="(meeting, index) in meetings", :key="index")
              b-td {{ meeting.dateString }}
              b-td {{ meeting.start_time }}
              b-td {{ meeting.end_time }}
              b-td {{ meeting.created_by }}
              b-td {{ meeting.location }}
              b-td {{ meeting.happy }}
              b-td {{ meeting.neutral }}
              b-td {{ meeting.sad }}
              b-td {{ meeting.happiness_index_text() }}
              b-td {{ meeting.partial ? $t('label.partial') : $t('label.complete') }}
              b-td(v-if="meeting.imageKey")
                b-button(
                    size="sm",
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'",
                    type="button",
                    variant="primary",
                    @click="openImage(meeting.imageKey)"
                    style="width: 5.5rem"
                  )
                    | {{ $t('message.open') }}            
              b-td(v-else)
                b-button(
                  size="sm",
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'",
                  type="button",
                  variant="info",
                  v-b-modal="'uploadImg' + index"
                  @click="openModal"
                  style="width: 5.5rem"
                )
                  | {{ $t('message.upload') }}
                b-modal(
                  :id="'uploadImg' + index"
                  centered
                  no-close-on-backdrop
                  modal-class="modal-info"
                  ok-variant="info"
                  cancel-variant="outline-secondary"
                  :title="$t('message.confirm_action')"
                  ok-title="OK"
                  :cancel-title="$t('message.cancel')"
                  :ok-disabled="!imgData.fileInfo"
                  @ok="saveImgInAWS(meeting.id)"
                  @hide="hideAction()"
                )
                  b-td(class="d-flex flex-column")
                    small(v-if="imgData.fileInfo") {{ $t('message.nameImg') }}: {{ imgData.fileInfo.name }}
                    div(class="align-self-start mt-2")
                      b-button(
                        @click="uploadImage()"
                        variant="light"
                        class=""
                      ) {{ imgData.fileInfo ? $t('message.modify') : $t('Upload') }}
              b-td
                delete-button(v-if="userRole === 'consultant'", v-b-modal="`modal ${index}`")
                b-modal(
                  v-if="userRole === 'consultant'"
                  :id="`modal ${index}`"
                  centered
                  no-close-on-backdrop
                  modal-class="modal-danger"
                  ok-variant="danger"
                  cancel-variant="outline-secondary"
                  :title="$t('message.confirm_action')"
                  :ok-title="$t('message.delete')"
                  :cancel-title="$t('message.cancel')"
                  @ok="deleteMeeting(meeting.id, meeting.improvements, meeting.recognitions)"
                )
                  | {{ $t('message.confirm_delete_daily_dialogue') }}
                feather-icon-button(
                  v-if="meeting.employeeSurveys && meeting.employeeSurveys.length",
                  icon="MessageSquareIcon",
                  variant="info",
                  @clicked="handleComments(true, meeting.employeeSurveys)"
                )
            image-compressor(
              style="display: none",
              :done="getImgCompressed",
              ref="compressor"
            )
          b-thead(v-else, head-variant="light")
            b-tr
              th {{ $t('happiness.date') }}
              th {{ $t('happiness.location') }}
              th {{ $t('message.tableHeader.state') }}
              th {{ $t('message.tableHeader.comments') }}
            b-tr(v-for="(survey, index) in employeeSurveysSelected", :key="index")
              b-td {{ survey.date }}
              b-td {{ survey.location }}
              b-td(:class="stateVariants[survey.answer]") {{ $t(`happiness.${survey.answer}`) }}
              b-td(:class="stateVariants[survey.answer]") {{ survey.comment }}

      b-button(class="mr-4 mb-1", :disabled="!meetings.length")
        json-excel(:data="meetings", :fields="excelFieldsDialogues")
          | {{ $t('download_xlsx_daily_dialogues') }}

      b-button(class="mr-4 mb-1", :disabled="!totalEmployeeSurveys.length")
        json-excel(:data="totalEmployeeSurveys", :fields="excelFieldsComments")
          | {{ $t('download_xlsx_comments') }}
</template>

<script>
import { onMounted, ref } from "@vue/composition-api/dist/vue-composition-api";
import store from "@/store";
import useNotifications from "@/composables/useNotifications";
import { BTable, BTableSimple, BThead, BTr, BTd, BCard, BModal, VBModal } from "bootstrap-vue";
import JsonExcel from "vue-json-excel";
import StatisticCardVerticalTotal from './charts/StatisticCardVerticalTotal.vue'
import StatisticCardVerticalIndex from './charts/StatisticCardVerticalIndex.vue'
import StatisticCardVerticalCompliance from './charts/StatisticCardVerticalCompliance.vue'
import BarChartLocation from './charts/ChartjsHorizontalBarChartLocation.vue'
import BarChartHappiness from './charts/ChartjsHorizontalBarChartHappiness.vue'
import Filters from "@/views/organization/Filters.vue";
import i18n from '@/libs/i18n'
import Ripple from 'vue-ripple-directive'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton'
import FeatherIconButton from '@/views/components/Shared/Buttons/FeatherIconButton'
import useCommon from '@/views/organization/useCommon'
import { get } from 'idb-keyval';
import HeatMapDialogues from './charts/ApexHeatMapDialogues.vue'
import ImageCompressor from '@/views/habit/ImageCompressor.vue'
import awsConnection from '@/views/habit/aws'
import { colors } from '@/constants'
import realmConnection from '@/views/habit/realm'

export default {
  components: {
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BCard,
    BModal,
    JsonExcel,
    StatisticCardVerticalTotal,
    StatisticCardVerticalIndex,
    StatisticCardVerticalCompliance,
    BarChartLocation,
    BarChartHappiness,
    Filters,
    Loading,
    DeleteButton,
    FeatherIconButton,
    HeatMapDialogues,
    ImageCompressor,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  setup() {
    const userData = store.state?.userStore?.userData;
    const userRole = userData.role;
    const client_id = userRole !== "admin" ? userData.client.$oid : null;
    const userLocations = userData.locations?.length ? userData.locations : null;
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const { handleError, openImage } = useCommon()
    const { getDatesFromRange, dateDiffInDays, happinessIndex, updateDailyDialogueWithKey } = useCommonDashboards()
    const { getItemsWithAggregate, ObjectId, updateItem, updateItems } = realmConnection()
    const meetings = ref([]);
    const meetings_total = ref(0);
    const happiness_index = ref(0)
    const compliance = ref(0)
    const locations = ref([])
    get('locations').then(response => {
      if (response) locations.value = response
      else if (localStorage.locations) locations.value = JSON.parse(localStorage.locations)
    })
    const locationFilter = ref(userLocations ? userLocations.map(e => e.value) : []);
    const zoneFilter = ref([]);
    const agencyFilter = ref([]);
    const locationDialogues = ref({})
    const locationHappiness = ref({})
    const now = new Date()
    const currentMonth = now.getMonth()
    const currentYear = now.getFullYear()
    const lastDayOfMonthDate = new Date(currentYear, currentMonth + 1, 0)
    const lastDayOfMonthNumber = lastDayOfMonthDate.getDate()
    const dateRangeFilter = ref(`01-${currentMonth + 1}-${currentYear} to ${lastDayOfMonthNumber}-${currentMonth + 1}-${currentYear}`)
    const isLoading = ref(true)
    const dialoguesPerDay = ref([])
    const { zone_agency_filter, default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')
    const commentsVisible = ref(false)
    const employeeSurveysSelected = ref([])
    const totalEmployeeSurveys = ref([])
    const stateVariants = { happy: 'text-success', neutral: 'text-warning', sad: 'text-danger' }
    const excelFieldsDialogues = ref({
      [i18n.t('happiness.date')]: "dateString",
      [i18n.t('happiness.start_time')]: "start_time",
      [i18n.t('happiness.end_time')]: "end_time",
      [i18n.t('happiness.created_by')]: "created_by",
      [i18n.t('happiness.location')]: "location",
      [i18n.t('happiness.happy')]: "happy",
      [i18n.t('happiness.neutral')]: "neutral",
      [i18n.t('happiness.sad')]: "sad",
      [i18n.t('happiness.mood')]: "happiness_index",
      [i18n.t('happiness.id')]: "id",
    })
    const excelFieldsComments = ref({
      [i18n.t('happiness.date')]: "date",
      [i18n.t('happiness.location')]: "location",
      [i18n.t('message.tableHeader.state')]: "answer",
      [i18n.t('message.tableHeader.comments')]: "comment",
    })

    // To upload image to AWS
    const compressor = ref(null)
    const imgData = ref({})
    const { singleUpload } = awsConnection()

    async function listMeetings() {
      isLoading.value = true
      const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)

      try {
        const initialQuery = {
          client_id: ObjectId(client_id),
          deleted: { $ne: true },
          date: { $gte: startFilter, $lt: endFilter },
        }

        const finalQuery = {}

        if (locationFilter.value.length > 0) {
          finalQuery['location._id'] = { $in: locationFilter.value }
        }

        if (zoneFilter.value.length > 0) {
          finalQuery['location.zone'] = { $in: zoneFilter.value }
        }

        if (agencyFilter.value.length > 0) {
          finalQuery['location.agency'] = { $in: agencyFilter.value }
        }

        const pipeline = [
          { $match: initialQuery },
          {
            $lookup: {
              from: 'location',
              localField: 'location',
              foreignField: '_id',
              pipeline: [ { $project: { location: 1, zone: 1, agency: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
              as: 'location'
            }
          },
          { $match: finalQuery },
          {
            $lookup: {
              from: 'worker',
              localField: 'created_by',
              foreignField: '_id',
              pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
              as: 'created_by'
            }
          },
          {
            $lookup: {
              from: 'employee_survey',
              localField: 'employeeSurveys',
              foreignField: '_id',
              pipeline: [
                { $project: { answer: 1, comment: 1, createdAt: 1 , location: 1 } },
                {
                  $lookup: {
                    from: 'location',
                    localField: 'location',
                    foreignField: '_id',
                    pipeline: [ { $project: { location: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
                    as: 'location',
                  },
                },
                { $addFields: { _id: { $toString: "$_id" }, location: { $arrayElemAt: ["$location", 0] } } }
              ],
              as: 'employeeSurveys'
            }
          },
          { $addFields: { _id: { $toString: "$_id" }, location: { $arrayElemAt: ["$location", 0] }, created_by: { $arrayElemAt: ["$created_by", 0] } } },
          { $sort: { date: 1 } },
        ]
      
        const items = await getItemsWithAggregate({ collection: 'meeting', pipeline })
        
        parseMeetingData(items, startFilter, endFilter)
      } catch (error) {
        console.log(error);
        handleError({ error, defaultMessage: i18n.t('message.err_daily_dialogues_list') })
      } finally {
        isLoading.value = false
      }
    }

    function parseMeetingData(items, startFilter, endFilter) {
      let dailyDialoguesData = { total: 0 }
      let dynamicDate = startFilter

      // Change the dates so that it matches the UTC time zone
      const timezoneOffsetHours = dynamicDate.getTimezoneOffset() / 60
      dynamicDate.setHours(dynamicDate.getHours() + timezoneOffsetHours)
      endFilter.setHours(endFilter.getHours() + timezoneOffsetHours)

      while (dynamicDate < endFilter) {
        const dateToAdd = dynamicDate.toLocaleDateString(`${default_language || 'en'}-US`)
        dailyDialoguesData[dateToAdd] = { dialogues: 0, images: 0, mood: 0, improvements: 0, recognitions: 0 }
        dynamicDate = new Date(dynamicDate.getFullYear(), dynamicDate.getMonth(), dynamicDate.getDate() + 1)
      }

      let meetings_data = []
      let happiness_data = {happy: 0, neutral: 0, sad: 0}
      let locationData = {}
      let locationDataDaily = {}
      let employeeSurveysData = []

      for (const meeting of items) {
        const date = meeting.date
        date.setHours(date.getHours() + timezoneOffsetHours)
        const dateString = date.toLocaleDateString(`${default_language || 'en'}-US`)
        const location = meeting.location?.location
        const locationId = meeting.location?._id
        const mood = meeting.happy + meeting.neutral + meeting.sad
        const imageKey = meeting.imageKey
        const improvements = meeting.improvements
        const recognitions = meeting.recognitions
        const employeeSurveys = meeting.employeeSurveys?.filter(e => e.comment).map(e => {
          const employeeSurveyDate = new Date(e.createdAt)
          employeeSurveyDate.setHours(employeeSurveyDate.getHours() + timezoneOffsetHours)
          const employeeSurveyDateString = employeeSurveyDate.toLocaleDateString(`${default_language || 'en'}-US`)
          return {
            date: employeeSurveyDateString,
            location: e.location.location,
            answer: e.answer,
            comment: e.comment,
          }
        }) || []
        employeeSurveysData = employeeSurveysData.concat(employeeSurveys);
        meetings_data.push({
            date,
            dateString,
            start_time: meeting.partial ? meeting.partialStart : meeting.start,
            end_time: meeting.partial ? meeting.partialEnd : meeting.end,
            created_by: meeting.created_by?.name,
            location: location,
            happy: meeting.happy,
            neutral: meeting.neutral,
            sad: meeting.sad,
            happiness_index: happinessIndex(meeting.happy, meeting.neutral, meeting.sad),
            happiness_index_text: function() {
              if (!this.happy && !this.neutral && !this.sad) return "-"
              return `${this.happiness_index}%`
            },
            imageKey,
            improvements,
            recognitions,
            id: meeting._id,
            employeeSurveys,
            partial: meeting.partial || false,
        });
        happiness_data.happy += meeting.happy
        happiness_data.neutral += meeting.neutral
        happiness_data.sad += meeting.sad

        // Data for charts
        if (location) {
          // Bar chart
          if (locationData.hasOwnProperty(location)) {
            locationData[location].dialogues++
            locationData[location].happy += meeting.happy
            locationData[location].neutral += meeting.neutral
            locationData[location].sad += meeting.sad
          } else {
            locationData[location] = {
              dialogues: 1,
              happy: meeting.happy,
              neutral: meeting.neutral,
              sad: meeting.sad,
              id: locationId,
            }
          }

          // Heat map
          if (!locationDataDaily.hasOwnProperty(location)) {
            locationDataDaily[location] = JSON.parse(JSON.stringify(dailyDialoguesData))
            locationDataDaily[location].id = locationId
          }
          locationDataDaily[location][dateString].dialogues++
          locationDataDaily[location][dateString].images += imageKey ? 1 : 0
          locationDataDaily[location][dateString].mood += mood
          locationDataDaily[location][dateString].improvements += improvements?.length || 0
          locationDataDaily[location][dateString].recognitions += recognitions?.length || 0
          locationDataDaily[location].total++
        }
      }

      // Global values
      meetings.value = meetings_data
      meetings_total.value = meetings_data.length
      happiness_index.value = (!happiness_data.happy && !happiness_data.neutral && !happiness_data.sad)
        ? "-"
        : `${happinessIndex(happiness_data.happy, happiness_data.neutral, happiness_data.sad)}%`
      totalEmployeeSurveys.value = employeeSurveysData

      // Compliance
      const qLocations = locationFilter.value.length ? locationFilter.value.length : locations.value.length
      // const filterDate = new Date(yearFilter.value, monthFilter.value + 1, 0)   // Last day of month of the filter date
      let qDays = 0
      // if (monthFilter.value === now.getMonth() && yearFilter.value === now.getFullYear()) qDays = now.getDate()
      // else if (filterDate < now) qDays = filterDate.getDate()
      if (endFilter < now) qDays = dateDiffInDays(startFilter, endFilter)
      else if (startFilter < now) qDays = dateDiffInDays(startFilter, now)
      compliance.value = complianceIndex(meetings_total.value, qLocations, qDays)

      // Sort charts data
      for (const location in locationData) {
        locationData[location].happinessIndex = happinessIndex(locationData[location].happy, locationData[location].neutral, locationData[location].sad)
      }

      let locationDataSortedByDialogues = Object.entries(locationData)
      let locationDataSortedByHappiness = Object.entries(locationData)

      locationDataSortedByDialogues.sort(function (a, b) {
        return b[1].dialogues - a[1].dialogues
      })

      locationDataSortedByHappiness.sort(function (a, b) {
        return b[1].happinessIndex - a[1].happinessIndex
      })

      const dataLimit = 10 // Display only the first 10 values in the chart
      const dialogueslimit = locationDataSortedByDialogues.length < dataLimit ? locationDataSortedByDialogues.length : dataLimit
      const happinessLimit = locationDataSortedByHappiness.length < dataLimit ? locationDataSortedByHappiness.length : dataLimit
      let locationDialoguesLabels = []
      let locationDialoguesValues = []
      let locationDialoguesIds = []
      let locationHappinessLabels = []
      let locationHappinessValues = []
      let locationHappinessIds = []

      for (let i = 0; i < dialogueslimit; i++) {
        locationDialoguesLabels.push(locationDataSortedByDialogues[i][0])
        locationDialoguesValues.push(locationDataSortedByDialogues[i][1].dialogues)
        locationDialoguesIds.push(locationDataSortedByDialogues[i][1].id)
      }

      for (let i = 0; i < happinessLimit; i++) {
        locationHappinessLabels.push(locationDataSortedByHappiness[i][0])
        locationHappinessValues.push(locationDataSortedByHappiness[i][1].happinessIndex)
        locationHappinessIds.push(locationDataSortedByHappiness[i][1].id)
      }

      locationDialogues.value = {
        labels: locationDialoguesLabels,
        data: locationDialoguesValues,
        additionalInfo: {
          locationIds: locationDialoguesIds,
        }
      }

      locationHappiness.value = {
        labels: locationHappinessLabels,
        data: locationHappinessValues,
        additionalInfo: {
          locationIds: locationHappinessIds,
        }
      }

      // Heat map
      const heatMapData = []

      for (const location in locationDataDaily) {
        const heatMapLocationData = {
          name: location,
          data: [],
          total: locationDataDaily[location].total,
          locationId: locationDataDaily[location].id
        }

        delete locationDataDaily[location].total
        delete locationDataDaily[location].id

        for (const date in locationDataDaily[location]) {
          heatMapLocationData.data.push({
            x: date,
            y: locationDataDaily[location][date].dialogues,
            images: locationDataDaily[location][date].images,
            mood: locationDataDaily[location][date].mood,
            improvements: locationDataDaily[location][date].improvements,
            recognitions: locationDataDaily[location][date].recognitions
          })
        }

        heatMapData.push(heatMapLocationData)
      }

      // Sort heat map data
      heatMapData.sort(function (a, b) {
        if (b.total === a.total) {
          if (a.name > b.name) return -1
          if (a.name < b.name) return 1
          return 0
        }
        return a.total - b.total
      })

      dialoguesPerDay.value = heatMapData
    }

    function complianceIndex(dialogues, locations, days) {
        if (!locations || !days) return "-"
        return `${Math.round(dialogues / (locations * days) * 1000) / 10}%`
    }

    function updateFilter(data) {
      locationFilter.value = data.locationFilter;
      dateRangeFilter.value = data.dateRangeFilter;
      zoneFilter.value = data.zoneFilter;
      agencyFilter.value = data.agencyFilter;
      
      listMeetings()
      handleComments(false)
    }

    function updateLocations(data) {
      if (!locations.value.length) {
        locations.value = data;
        listMeetings();
      }
    }

    const handleComments = (showComments, employeeSurveys) => {
      employeeSurveysSelected.value = employeeSurveys || []
      commentsVisible.value = showComments
    }

    const deleteMeeting = async (id, improvements, recognitions) => {
      try {
        const query = { _id: ObjectId(id) }
        const action = { $set: { deleted: true } }

        await updateItem({ collection: 'meeting', query, action })

        listMeetings()
        showSuccessMessage(i18n.t('message.daily_dialogue_remove'))
        
        // Delete improvements associated with daily dialogue
        if (improvements?.length) {
          try {
            await updateItems({ collection: 'improvement', query: { _id: { $in: improvements } }, action })
          } catch (error) {
            console.log(error)
          }
        }

        // Delete recognitions associated with daily dialogue
        if (recognitions?.length) {
          try {
            await updateItems({ collection: 'recognition', query: { _id: { $in: recognitions } }, action })
          } catch (error) {
            console.log(error)
          }
        }
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.daily_dialogue_remove_error'))
      }
    }

    const openModal = () => {
      uploadImage()
    }

    const uploadImage = () => {
      compressor.value.$el.click()
    }

    const getImgCompressed = (obj) => {
      imgData.value = {
        fileInfo: obj.compressed,
        destinationFolder: `${userData.client.$oid}/daily-dialogues`
      }
    }

    const hideAction = () => {
      imgData.value = {}
    }

    const saveImgInAWS = async (id) => {
      isLoading.value = true
      try {
       const key =  await singleUpload(imgData.value.fileInfo, imgData.value.destinationFolder)
       await updateDailyDialogueWithKey(id, key)
       listMeetings()
      } catch (err) {
        console.log(err)
      }
      isLoading.value = false
    }

    onMounted(() => {
      listMeetings()
    })

    return {
      meetings,
      meetings_total,
      happiness_index,
      compliance,
      updateFilter,
      updateLocations,
      excelFieldsDialogues,
      excelFieldsComments,
      openImage,
      locationDialogues,
      locationHappiness,
      isLoading,
      zone_agency_filter,
      userRole,
      deleteMeeting,
      dialoguesPerDay,
      commentsVisible,
      handleComments,
      employeeSurveysSelected,
      stateVariants,
      totalEmployeeSurveys,
      openModal,
      compressor,
      imgData,
      uploadImage,
      getImgCompressed,
      hideAction,
      saveImgInAWS,
      colors
    };
  },
};
</script>